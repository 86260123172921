import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82')
];

export const server_loads = [0,10,11,12,13];

export const dictionary = {
		"/(home)": [~18],
		"/about-us": [~28],
		"/ama": [29],
		"/authors/[uid]": [~31],
		"/auth/callback": [30],
		"/(screeners)/best-singapore-dividend-stocks": [~19,[5]],
		"/(screeners)/best-singapore-high-dividend-stocks": [~20,[5]],
		"/(screeners)/best-singapore-reits": [~21,[5]],
		"/contact": [32],
		"/disclaimer": [~33],
		"/editorial-guidelines": [34],
		"/embed/cash-mgmt-yield": [~35,[7]],
		"/embed/compare-yield": [~36,[7]],
		"/embed/dividend-calculator/[ticker]": [~37,[7]],
		"/embed/price-to-book": [~38,[7]],
		"/embed/reit-yields": [~39,[7]],
		"/embed/savings-account-calculator": [~40,[7]],
		"/embed/ssb-swap-calculator": [~41,[7]],
		"/etfs": [~42,[8]],
		"/etfs/all": [~44,[8]],
		"/etfs/themes/[theme]": [~45],
		"/etfs/[ticker]": [~43,[8]],
		"/events": [~46],
		"/(unauthenticated)/forgot-password": [23,[6]],
		"/glossary": [~47],
		"/(catalog)/insights/[...theme]": [~17,[4]],
		"/learn-and-grow/[category]": [~48],
		"/(article)/lessons/[slug]": [~15,[2]],
		"/(unauthenticated)/login": [24,[6]],
		"/my": [~49],
		"/newsletter-signup": [51],
		"/newsletter": [~50],
		"/partners": [~52],
		"/privacy": [~53],
		"/promo": [~54],
		"/promo/expired": [~57],
		"/promo/[slug]": [~55,[10]],
		"/promo/[slug]/thank-you": [56,[10]],
		"/quote/[ticker]": [~58,[11]],
		"/quote/[ticker]/dividend": [~59,[11]],
		"/reits": [60,[12]],
		"/reits/all": [~61],
		"/reits/compare": [~62],
		"/reits/sectors": [63,[12]],
		"/reits/sectors/[theme]": [~64],
		"/(unauthenticated)/reset-password/[email]": [25,[6]],
		"/search": [65],
		"/securities/[[type=securityType]]": [~66],
		"/(unauthenticated)/signup": [26],
		"/terms-of-use": [~67],
		"/tools": [~68],
		"/tools/best-brokerage-accounts": [~69],
		"/tools/best-savings-accounts": [~70],
		"/tools/compare-yield": [~71,[13]],
		"/tools/compare-yield/cash-mgt": [~72,[13]],
		"/tools/compare-yield/fixed-deposit": [~73,[13]],
		"/tools/compare-yield/ssb": [~74,[13]],
		"/tools/compare-yield/tbill": [~75,[13]],
		"/tools/find-savings-account": [76],
		"/tools/find-savings-account/hang-tight": [77],
		"/tools/passive-income-calculator": [~78],
		"/tools/srs-tax-savings-calculator": [79],
		"/tools/ssb-interest-rate-projection": [~80],
		"/tools/ssb-swap-calculator": [~81],
		"/tools/tbill-calculator": [~82],
		"/(screeners)/upcoming-dividends": [~22,[5]],
		"/(unauthenticated)/verify/[payload]": [27,[6]],
		"/(authenticated)/watchlist": [16,[3]],
		"/(article)/[slug]": [~14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';